import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import reifenwechseln from "../images/reifenwechseln.png";
import Markenlogos from "../components/markenlogos.js";
import Dienstleistungenicons from "../components/dienstleistungenicons.js";
import Containerueberuns from "../components/containerueberuns";
import Counter from "../components/counter.js";
import "../css/index.css";

import favicon from '../assets/favicon.ico';

const IndexPage = () => {

  return (
    <div className="body">
      <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Navbar />
      <div>
        <section className="presentation">
          <div className="introduction">
            <div className="intro-text">
              <div className="text">
                <h3>Herzlich Willkommen im</h3>
                <h1>Pneuhaus</h1>
                <h1>Rogenmoser</h1>
                <h2>Ihr Reifenspezialist in der Region</h2>
              </div>
              <div className="clickbutton">
                <a href="/kontakt">
                  <button className="button">
                    Termin vereinbaren
                  </button>
                </a>
              </div>
            </div>
            <div>
              <img className="picture" src={reifenwechseln} alt="Bild Reifen wechseln" />
            </div>
          </div>
        </section>
      </div>

      <Containerueberuns />
      <Counter />
      <Markenlogos />
      <Dienstleistungenicons />
      <Footer />

    </div>
  );
};

export default IndexPage;

