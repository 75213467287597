import React from "react";
import "../css/containerueberuns.css";

const IndexPage = () => {
  return (
    <div>
      <body>
        <section className="presentation">
          <div className="container">
            <div className="ueberunstext">
              <h2>Über uns</h2>
              <p>Im Jahr 1998 ist das Pneuhaus Rogenmoser mit dem Sitz in Auw gegründet worden. Das Familienunternehmen ist in den letzten Jahren stets gewachsen. Wir dürfen bis heute einen konstanten, zufriedenen Kundenstamm betreuen. Von Montag bis Samstag sind wir gerne Ihr Ansprechspartner bei Anliegen rund um den Pneuservice. </p>
            </div>
            <div className="clickbutton">
              <a href="/ueberuns">
                <button className="button">
                  Mehr
                </button>
              </a>
            </div>
          </div>
        </section>
      </body>
    </div>
  );
};

export default IndexPage;

