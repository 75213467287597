import React from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import "../css/counter.css";

const IndexPage = () => {

  return (
    <div>
        <section className="presentation">
          <div className="containercounter">
            <div className="counter">
              <CountUp end={1890} delay={0}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <h2>Kunden</h2>
            </div>
          </div>

          <div className="containercounter">
            <div className="counter">
              <CountUp end={14} delay={0}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <h2>Garagenmietplätze</h2>
            </div>
          </div>

          <div className="containercounter">
            <div className="counter">
              <CountUp end={3200} delay={0}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <h2>Plätze im Reifenhotel</h2>
            </div>
          </div>

        </section>
    </div>
  );
};

export default IndexPage;