import React from "react";
import "../css/markenlogos.css";



const IndexPage = () => {
  return (
    <div>
      <body>
        <section className="presentation">
          <div className="container">
            <div className="markentext">
              <h2> Über X Pneumarken haben wir im Angebot </h2>
            </div>
            <div className="markentext">
              <p> <br></br> <br></br> Bist du auf der Suche nach einer spezielle Marke oder einem ungewöhnlichen Modell? Sende uns kostenlos eine Anfrage!  </p>
            </div>
            <div className="clickbutton">
              <a href="/kontakt">
                <button className="button">
                  Anfrage
                </button>
              </a>
            </div>
          </div>
        </section>
      </body>
    </div>
  );
};

export default IndexPage;