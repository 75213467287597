import React from "react";
import { Link } from "gatsby";

import "../css/dienstleistungenicons.css";
import Personenwagen from "../images/Personenwagen.png";
import Lastwagen from "../images/Lastwagen.png";
import Landwirtschaft from "../images/Landwirtschaft.png";
import Stapler from "../images/Stapler.png";
import Service from "../images/Service.png";



const IndexPage = () => {
  return (
    <div>

      <body>

        <section className="presentation">
          <div className="container">
            <div className="text">
              <h2> Unsere Dienstleistungen </h2>
            </div>
            <div className="dienstleistungenicon">
              <Link to="/dienstleistungen#personenwagen">
                <img className="icon" src={Personenwagen} alt="Personenwagen" />
              </Link>
              <Link to="/dienstleistungen#lastwagen">
                <img className="icon" src={Lastwagen} alt="Lastwagen" />
              </Link>
              <Link to="/dienstleistungen#landwirtschaft">
                <img className="icon" src={Landwirtschaft} alt="Landwirtschaft" />
              </Link>
              <Link to="/dienstleistungen#stapler">
                <img className="icon" src={Stapler} alt="Stapler" />
              </Link>
              <Link to="/dienstleistungen#service">
                <img className="icon" src={Service} alt="Service" />
              </Link>
            </div>
          </div>
        </section>

      </body>

    </div>
  );
};

export default IndexPage;